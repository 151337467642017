<template>
  <div id="user-list" class="car-brand">
    <AgentAddNew
      v-model="isAddNewCarBrandSidebarActive"
      :id="agentId"
      :type-options="typeOptions"
      :location-options="locationOptions"
      @reset-id="agentId = null"
    ></AgentAddNew>

    <!-- list filters -->
    <v-card>
      <v-card-title class="d-flex">
        <div class="mr-auto">
          Agents
          <!-- <span class="car-brand-count mt-1 ml-1">({{ filteredAgents.length +  '/' + agents.length }})</span> -->
        </div>
        <div class="d-flex">
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
            clearable
            class="text-search me-3 mb-4"
          >
          </v-text-field>
          <v-btn icon class="mb-4" @click="showFilters = !showFilters">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="24" class="me-1" v-bind="attrs" v-on="on">
                  {{ icons.mdiFilter }}
                </v-icon>
              </template>
              <span>Filters</span>
            </v-tooltip>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon size="24" class="me-1" v-bind="attrs" v-on="on">
                      {{ icons.mdiSort }}
                    </v-icon>
                  </template>
                  <span>Sort By</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in sortOptions" :key="index" @click="sortBy = item.value">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <v-btn
            color="primary"
            class="mb-4 ml-3"
            @click.stop="addAgent"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Add</span>
          </v-btn> -->
        </div>
      </v-card-title>

      <!-- actions -->
      <v-card-text class="d-flex flex-wrap pb-0" v-if="showFilters">
        <v-select
          v-model="statusFilter"
          placeholder="Select Status"
          :items="statusOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
        ></v-select>

        <v-select
          v-model="locationFilter"
          placeholder="Select Location"
          :items="locationOptions"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
        ></v-select>
      </v-card-text>

      <p class="ml-2">{{ filteredAgents.length || 'No' }} records found</p>
      <v-row>
        <v-col cols="12" lg="3">
          <AddNewCard title="Add Agent" textSize="text-h6" @click="addAgent" />
        </v-col>
        <v-col
          cols="12"
          lg="3"
          v-for="({ id, fullname, location_id, email, phone, active, updatedon }, c) in paginatedAgents[page - 1]"
          :key="c"
        >
          <AgentCard
            :id="id"
            :agentName="fullname"
            :isActive="active"
            :email="email"
            :phone="phone"
            :locationId="location_id"
            :updatedOn="formatDate(updatedon.toDate(), 'DD-MMM-YYYY hh:mm A')"
            @edit-agent="editAgent"
          />
        </v-col>
      </v-row>

      <div class="d-flex">
        <div class="mt-2 mr-auto ml-5">
          <span>Items per page: {{ itemsPerPage }} </span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dark v-bind="attrs" v-on="on">
                {{ icons.mdiMenuDown }}
              </v-icon>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in itemsPerPageOptions" :key="index" @click="itemsPerPage = item.title">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-flex">
          <span class="mt-2">{{ countText }}</span>
          <v-pagination v-model="page" :length="paginatedAgents.length"></v-pagination>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { mdiPlus, mdiFilterVariant, mdiFilter, mdiSort, mdiMenuDown } from '@mdi/js'
import useAgent from './useAgent'
import useMoment from '../../../composables/useMoment'
import AgentCard from './AgentCard.vue'
import AgentAddNew from './AgentAddNew.vue'
import AddNewCard from '@/components/AddNewCard.vue'

export default {
  components: {
    AgentCard,
    AgentAddNew,
    AddNewCard,
  },
  setup() {
    const isAddNewCarBrandSidebarActive = ref(false)
    const agentId = ref(null)
    const showFilters = ref(true)
    const statusOptions = [
      { title: 'Active', value: true },
      { title: 'Inactive', value: false },
    ]

    const typeOptions = [{ title: 'Popular', value: true }]

    const sortOptions = [
      { title: 'Last Updated', value: { by: 'updatedon', order: 'desc' } },
      { title: 'First Updated', value: { by: 'updatedon', order: 'asc' } },
      { title: 'Name : A to Z', value: { by: 'fullname', order: 'asc' } },
      { title: 'Name : Z to A', value: { by: 'fullname', order: 'desc' } },
      { title: 'Location : A to Z', value: { by: 'locationId', order: 'asc' } },
      { title: 'Location : Z to A', value: { by: 'locationId', order: 'desc' } },
    ]

    const itemsPerPageOptions = [{ title: '16' }, { title: '32' }, { title: '48' }, { title: 'All' }]

    const {
      page,
      itemsPerPage,
      agents,
      filteredAgents,
      paginatedAgents,
      searchQuery,
      statusFilter,
      locationFilter,
      sortBy,
      loading,
      locationOptions,

      unsubscribeAgents,
    } = useAgent()

    const countText = computed(() => {
      if (paginatedAgents.value.length) {
        const previous = itemsPerPage.value == 'All' ? 0 : (page.value - 1) * itemsPerPage.value
        const start = previous + 1
        const end = previous + paginatedAgents.value[page.value - 1].length
        return `${start}-${end} of ${filteredAgents.value.length}`
      }
      return ''
    })

    const { formatDate } = useMoment()

    const addAgent = () => {
      agentId.value = null
      isAddNewCarBrandSidebarActive.value = !isAddNewCarBrandSidebarActive.value
    }

    const editAgent = id => {
      agentId.value = id
      isAddNewCarBrandSidebarActive.value = !isAddNewCarBrandSidebarActive.value
    }

    onUnmounted(() => {
      unsubscribeAgents()
    })

    return {
      page,
      itemsPerPage,
      isAddNewCarBrandSidebarActive,
      agentId,
      showFilters,
      statusOptions,
      typeOptions,
      agents,
      filteredAgents,
      paginatedAgents,
      searchQuery,
      statusFilter,
      locationFilter,
      sortBy,
      sortOptions,
      loading,
      formatDate,
      editAgent,
      addAgent,
      itemsPerPageOptions,
      countText,
      locationOptions,
      // icons
      icons: {
        mdiPlus,
        mdiFilterVariant,
        mdiFilter,
        mdiSort,
        mdiMenuDown,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.car-brand {
  .car-brand-count {
    font-size: 1rem;
  }
  .v-input {
    max-width: 250px;
  }
  .text-search {
    min-width: 300px;
  }
}
</style>
