<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :elevation="hover ? 12 : 2"
      class="car-brand-card mx-auto"
      max-width="344"
      outlined
      hover
      @click="$emit('edit-agent', id)"
    >
      <v-list-item three-line class="mt-3">
        <v-list-item-content>
          <!-- <div class="text-overline mb-4">
            {{ type }}
          </div> -->
          <v-list-item-title class="text-h7 mb-1">
            {{ agentName }}
          </v-list-item-title>
         
              <v-list-item-subtitle class="text-xs">{{ email }}</v-list-item-subtitle>
               <v-list-item-subtitle class="text-xs"> {{ phone }}</v-list-item-subtitle>
          <v-list-item-subtitle class="last-modified d-flex">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" x-small>{{ icons.mdiCalendarBlankOutline }}</v-icon>
              </template>
               <span>Last Modified</span>
            </v-tooltip>
            <div class="ml-1">{{ updatedOn }}</div>
          </v-list-item-subtitle>
        </v-list-item-content>

      </v-list-item>
      <div>
        <v-chip
          class="ma-2 v-chip-light-bg primary--text location-icon"
          color="primary"
          small
        >
          {{locationId}}
        </v-chip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="status-icon" x-small v-bind="attrs" v-on="on" :color="isActive ? 'success' : 'error'">{{ icons.mdiCircle }}</v-icon>
          </template>
          <span>{{ isActive ? 'Active' : 'Inactive' }}</span>
        </v-tooltip>
        <v-icon class="ml-2 edit-icon" small>{{ icons.mdiPencil }}</v-icon>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import { mdiCircle, mdiPencil, mdiBookmark, mdiCheck, mdiCalendarBlankOutline } from '@mdi/js'

export default {
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
    agentName: {
      type: String,
      required: true,
    },
    locationId: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
    updatedOn: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    return {
      icons: {
        mdiCircle,
        mdiPencil,
        mdiBookmark,
        mdiCheck,
        mdiCalendarBlankOutline,
      },
    }
  },
}
</script>

<style scoped lang="scss">
.car-brand-card {
  .status-icon {
    position: absolute;
    cursor: pointer;
    left: 10px;
    top: 5px;
  }

  .location-icon {
    position: absolute;
    cursor: pointer;
    right: 25px;
    top: -2px;
  }

  .edit-icon {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 7px;
    color: rgb(88, 88, 88);
  }

  .last-modified {
    font-size: 0.7rem;
  }
}
</style>
