<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="val => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">{{ id ? 'Update' : 'Add' }} Agent</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="resetcarBrandData">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent>
          <v-text-field
            v-model="agentData.fullname"
            @keyup="() => agentData.fullname = capitalizeSentence(agentData.fullname)"
            outlined
            dense
            :rules="[validators.required]"
            label="Agent *"
            placeholder="Agent"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-select
            v-model="agentData.location_id"
            outlined
            :rules="[validators.required]"
            label="Location *"
            placeholder="Location"
            :items="locationOptions"
            clearable
            dense
            hide-details="auto"
            class="mb-6"
          ></v-select>

          <v-text-field
            v-model="agentData.email"
            outlined
            dense
            validate-on-blur
            :rules="[validators.required, validators.emailValidator]"
            label="Email *"
            placeholder="Email"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="agentData.email1"
            outlined
            dense
            validate-on-blur
            :rules="[validators.emailValidator]"
            label="Alternative Email 1"
            placeholder="Alternative Email 1"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="agentData.email2"
            outlined
            dense
            validate-on-blur
            :rules="[validators.emailValidator]"
            label="Alternative Email 2"
            placeholder="Alternative Email 2"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
          v-if="country == 'UK'"
            v-model="agentData.email3"
            outlined
            dense
            validate-on-blur
            :rules="[validators.emailValidator]"
            label="Alternative Email 3"
            placeholder="Alternative Email 3"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="agentData.phone"
            outlined
            dense
            validate-on-blur
            :rules="[validators.required, validators.phoneNumberValidator]"
            label="Phone *"
            placeholder="Phone"
            hide-details="auto"
            class="mb-6"
            type="tel"
          ></v-text-field>

          <v-text-field
            v-model="agentData.phone1"
            outlined
            dense
            validate-on-blur
            :rules="[validators.phoneNumberValidator]"
            label="Alternative Phone 1"
            placeholder="Alternative Phone 1"
            hide-details="auto"
            class="mb-6"
            type="tel"
          ></v-text-field>
          <v-text-field
            v-model="agentData.phone2"
            outlined
            dense
            validate-on-blur
            :rules="[validators.phoneNumberValidator]"
            label="Alternative Phone 2"
            placeholder="Alternative Phone 2"
            hide-details="auto"
            class="mb-6"
            type="tel"
          ></v-text-field>

          <v-switch
            v-model="agentData.active"
            inset
            :color="agentData.active ? 'success' : 'error'"
            :label="`${agentData.active ? 'Active' : 'Inactive'}`"
          ></v-switch>

          <v-alert dense text color="error" v-if="error">
            {{ error }}
          </v-alert>

          <v-btn color="primary" class="me-3" @click.prevent="onSubmit">
            {{ id ? 'Update' : 'Add' }}
          </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetcarBrandData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiCircle } from '@mdi/js'
import { find } from 'lodash'
import { ref, watch } from '@vue/composition-api'
import { required, emailValidator, phoneNumberValidator } from '@core/utils/validation'
import useAgent from './useAgent'
import { capitalizeSentence } from '@core/utils/filter'
import useAppConfig from '@/@core/@app-config/useAppConfig'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    id: {
      type: String | null,
      required: true,
    },
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
    locationOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { country } = useAppConfig()
    const fileRules = [v => !!v || 'File is required', v => (v && v.size > 0) || 'File is required']
    const valid = ref(false)
    const form = ref(null)

    const { addAgent, editAgent, agentData, blankAgentData, error, agents } = useAgent()

    const validate = () => {
      form.value.validate()
    }

    const resetForm = async () => {
      await form.value.reset()
      agentData.value = JSON.parse(JSON.stringify(blankAgentData))
    }

    const resetcarBrandData = () => {
      resetForm()
      error.value = null
      emit('reset-id')
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = async () => {
      await validate()
      if (valid.value) {
        if (props.id) {
          await editAgent(props.id)
        } else {
          await addAgent()
        }
        if (!error.value) {
          emit('update:is-add-new-user-sidebar-active', false)
          resetcarBrandData()
        }
      } else {
        validate()
      }
    }

    watch(
      () => props.id,
      () => {
        if (props.id) {
          const agent = find(agents.value, ['id', props.id])
          agentData.value.active = agent.active
          agentData.value.fullname = agent.fullname
          agentData.value.email = agent.email
          agentData.value.email1 = agent.email1
          agentData.value.email2 = agent.email2
          agentData.value.phone = agent.phone
          agentData.value.phone1 = agent.phone1
          agentData.value.phone2 = agent.phone2
          agentData.value.location_id = agent.location_id

          if (country.value == 'UK') {
            agentData.value.email3 = agent.email3
          }
        }
      },
      { immediate: true },
    )

    watch(
      () => props.isAddNewUserSidebarActive,
      () => {
        if (!props.isAddNewUserSidebarActive) {
          resetcarBrandData()
        }
      },
    )

    return {
      resetcarBrandData,
      form,
      onSubmit,
      agentData,
      valid,
      validate,
      error,
      fileRules,
      capitalizeSentence,
      country,

      // validation
      validators: { required, emailValidator, phoneNumberValidator },
      icons: {
        mdiClose,
        mdiCircle,
      },
    }
  },
}
</script>
