var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"car-brand",attrs:{"id":"user-list"}},[_c('AgentAddNew',{attrs:{"id":_vm.agentId,"type-options":_vm.typeOptions,"location-options":_vm.locationOptions},on:{"reset-id":function($event){_vm.agentId = null}},model:{value:(_vm.isAddNewCarBrandSidebarActive),callback:function ($$v) {_vm.isAddNewCarBrandSidebarActive=$$v},expression:"isAddNewCarBrandSidebarActive"}}),_c('v-card',[_c('v-card-title',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-auto"},[_vm._v(" Agents ")]),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"text-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":"","clearable":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-btn',{staticClass:"mb-4",attrs:{"icon":""},on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-1",attrs:{"size":"24"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiFilter)+" ")])]}}])},[_c('span',[_vm._v("Filters")])])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-1",attrs:{"size":"24"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiSort)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sort By")])])],1)]}}])},[_c('v-list',_vm._l((_vm.sortOptions),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.sortBy = item.value}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)]),(_vm.showFilters)?_c('v-card-text',{staticClass:"d-flex flex-wrap pb-0"},[_c('v-select',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Select Status","items":_vm.statusOptions,"item-text":"title","item-value":"value","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}}),_c('v-select',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Select Location","items":_vm.locationOptions,"outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.locationFilter),callback:function ($$v) {_vm.locationFilter=$$v},expression:"locationFilter"}})],1):_vm._e(),_c('p',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.filteredAgents.length || 'No')+" records found")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('AddNewCard',{attrs:{"title":"Add Agent","textSize":"text-h6"},on:{"click":_vm.addAgent}})],1),_vm._l((_vm.paginatedAgents[_vm.page - 1]),function(ref,c){
var id = ref.id;
var fullname = ref.fullname;
var location_id = ref.location_id;
var email = ref.email;
var phone = ref.phone;
var active = ref.active;
var updatedon = ref.updatedon;
return _c('v-col',{key:c,attrs:{"cols":"12","lg":"3"}},[_c('AgentCard',{attrs:{"id":id,"agentName":fullname,"isActive":active,"email":email,"phone":phone,"locationId":location_id,"updatedOn":_vm.formatDate(updatedon.toDate(), 'DD-MMM-YYYY hh:mm A')},on:{"edit-agent":_vm.editAgent}})],1)})],2),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mt-2 mr-auto ml-5"},[_c('span',[_vm._v("Items per page: "+_vm._s(_vm.itemsPerPage)+" ")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiMenuDown)+" ")])]}}])},[_c('v-list',_vm._l((_vm.itemsPerPageOptions),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.itemsPerPage = item.title}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.countText))]),_c('v-pagination',{attrs:{"length":_vm.paginatedAgents.length},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }